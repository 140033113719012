import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  min-height: 18px; /* changed from height to min-height */
  overflow-y: hidden;
  padding: 5px 0; /* Optional padding for spacing adjustment */

  span {
    font-size: ${(props) => (props.isShort ? "13px" : "10px")};
    margin-right: 4px;
    display: inline-block; /* changed to inline-block for better layout control */
  }

  div {
    position: relative; 
    left: 10px;
    display: flex; /* This makes div a flex container */
    justify-content: center; /* Now this works */
    align-items: center;
  }

  a {
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin: 0 2px; /* optional spacing for better alignment */

    img {
      width: ${(props) => (props.isShort ? "56px" : "54px")};
      margin-bottom: 1px;
    }

    sup {
      color: rgb(255, 87, 34);
      font-size: ${(props) => (props.isShort ? "14px" : "11px")};
    }
  }

  img {
    margin-bottom: 1px;
  }

  h3 {
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export default FooterContainer;
