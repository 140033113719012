import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import translations from "../translations";
import { setJourney } from "../../../../../../../Store/Dispatcher/journey";
import useSendQuery from "../../../../../../../Hooks/useSendQuery/useSendQuery";
import AdUnit from "./styles";

const AD_UNIT_CONFIG = {
  DIV_ID: "div-gpt-ad-1695628300486-0",
  DIMENSIONS: [300, 250],
  DISPLAY_DELAY: 4000,
  GPT_SCRIPT_URL: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
  AD_UNIT_DATA_URL: "https://cdn.unibotscdn.com/headerbidding/irctc.json",
  REFRESH_INTERVAL: 30000, // Refresh every 30 seconds
};

const AD = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [adUnitName, setAdUnitName] = useState(null);
  const [error, setError] = useState(null);
  const [adSlot, setAdSlot] = useState(null);

  const lang = useGetReduxState().behaviour.lang;
  const langText = translations[lang];
  const { sendQueryPayload } = useSendQuery(() => {});

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const loadGPTScript = () => {
    return new Promise((resolve, reject) => {
      if (window.googletag) {
        resolve(undefined);
        return;
      }

      const script = document.createElement("script");
      script.src = AD_UNIT_CONFIG.GPT_SCRIPT_URL;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  const fetchAdUnitData = async () => {
    try {
      const response = await fetch(AD_UNIT_CONFIG.AD_UNIT_DATA_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      if (!data.Adunit_IR_UB_300x250) {
        throw new Error("Ad unit data is missing required field");
      }
      
      return data.Adunit_IR_UB_300x250;
    } catch (error) {
      console.error("Error fetching ad unit data:", error);
      throw error;
    }
  };

  const refreshAd = () => {
    if (!adSlot || !window.googletag) return;

    googletag.cmd.push(() => {
      try {
        googletag.pubads().refresh([adSlot]);
        console.log("Ad refreshed successfully");
      } catch (error) {
        console.error("Error refreshing ad:", error);
        setError("Failed to refresh advertisement");
      }
    });
  };

  const defineGPTSlot = (adUnitPath) => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      try {
        const slot = googletag
          .defineSlot(
            adUnitPath, 
            AD_UNIT_CONFIG.DIMENSIONS, 
            AD_UNIT_CONFIG.DIV_ID
          )
          .addService(googletag.pubads());
        
        setAdSlot(slot);
        googletag.pubads().enableSingleRequest();
        
        googletag.pubads().addEventListener("slotRenderEnded", event => {
          const slotId = event.slot.getSlotElementId();
          if ((slotId == AD_UNIT_CONFIG.DIV_ID) && !(event.isEmpty)) {
            let x1 = document.querySelector('.sc-bbSZdi.iLIiuT') as HTMLElement;;
            if (x1) {
              x1.style.background = "none";
            }
          }
        });

        googletag.enableServices();
        
        // Display the ad initially
        setIsAdVisible(true);
        // googletag.display(AD_UNIT_CONFIG.DIV_ID);
        
        // Initial refresh to show the first ad
        googletag.pubads().refresh([slot]);
      } catch (error) {
        console.error("Error defining GPT slot:", error);
        setError("Failed to initialize advertisement");
      }
    });
  };

  useEffect(() => {
    if (isMobileDevice()) {
      return;
    }

    const initializeAd = async () => {
      try {
        const [_, adUnitPath] = await Promise.all([
          loadGPTScript(),
          fetchAdUnitData()
        ]);
        
        setAdUnitName(adUnitPath);
        setIsScriptLoaded(true);
        defineGPTSlot(adUnitPath);
      } catch (error) {
        console.error("Failed to initialize ad:", error);
        setError("Failed to load advertisement");
      }
    };

    initializeAd();

    return () => {
      if (window.googletag && googletag.destroySlots) {
        googletag.cmd.push(() => googletag.destroySlots());
      }
    };
  }, []);

  // Set up refresh interval once the ad is loaded
  useEffect(() => {
    if (isMobileDevice() || !isScriptLoaded || !adUnitName || !adSlot) {
      return;
    }

    const refreshTimer = setInterval(refreshAd, AD_UNIT_CONFIG.REFRESH_INTERVAL);

    // return () => {
    //   clearInterval(refreshTimer);
    // };
  }, [isScriptLoaded, adUnitName, adSlot]);

  if (isMobileDevice() || error) {
    return null;
  }

  return (
    <AdUnit>
      <div
        id={AD_UNIT_CONFIG.DIV_ID}
        style={{ 
          width: `${AD_UNIT_CONFIG.DIMENSIONS[0]}px`, 
          height: `${AD_UNIT_CONFIG.DIMENSIONS[1]}px`,
          margin: "0 auto" 
        }}
      />
    </AdUnit>
  );
};

export default AD;