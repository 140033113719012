import styled from "styled-components";
import React from "react"; // import React if you haven't already

interface AdUnitProps {
  id?: string;
  theme?: any;
  children?: React.ReactNode;  // Explicitly allow children
}

const AdUnit = styled.div<AdUnitProps>`
  display: block !important;
  background-image: url(https://cdn.jsdelivr.net/gh/corover/assets@4feb/askdisha-bucket/Mahakumbh%20Ad%20Creative%20300x250.gif);
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center;
  min-width: 300px;
  min-height: 250px;
`;

export default AdUnit;
